
import { useRouter } from 'next/router';
import Link from 'next/link';

//react icons
import { MdClose } from "react-icons/md";

//component
import { Button } from '@/src/atoms-new/Button';

interface GuestMenuProps {
    hideModal: () => void;
}

export const GuestMenu = ({
    hideModal
}:GuestMenuProps) => {
    const router = useRouter();


    return (
        <div className='flex flex-col w-full h-full gap-0'>
            <div className='w-full flex justify-end '>
                <div className='cursor-pointer' onClick={hideModal}>
                    <MdClose size={28}/>
                </div>
            </div>

            <div className='flex flex-row gap-5 pb-3 pt-5'>
                <Button
                    color='bg-[#F2503A] hover:bg-orange-600'
                    textColor='text-white'
                    borderColor=''
                    className='w-full'
                    onClick={() => router.push('/auth/signup')}
                    >Create Account
                </Button>
            </div>

            <div className='flex flex-col gap-0'>
                <Link href='/auth/login' className='p-4 w-full border-b cursor-pointer hover:bg-gray-100'>
                    <p className='text-md text-gray-600'>Login</p>
                </Link>

                <Link href='/search-lessons' className='p-4 w-full cursor-pointer hover:bg-gray-100'>
                    <p className='text-md text-gray-600'>Search Lessons</p>
                </Link>
                <Link href='/search-instructors' className='p-4 w-full border-b cursor-pointer hover:bg-gray-100'>
                    <p className='text-md text-gray-600'>Search Instructors</p>
                </Link>
                
                <Link href='/join-ambition/instructor' className='p-4 w-full cursor-pointer hover:bg-gray-100'>
                    <p className='text-md text-gray-600'>Become an Instructor</p>
                </Link>
            </div>
        </div>

    )
}